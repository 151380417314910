import React, {useEffect, useRef, useState} from 'react';
import {Box, Container, Slide, Stack, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import {
    bannerStyles, bottomStyles, circularDesign, circularDesignLarger,
    mainOuterBox, rightStyles,
} from "./stylesheets/servicesStyles";

import ar from "./assets/services/s2.png";
import ao from "./assets/services/s3.png";
import ga from "./assets/services/s4.png";
import gd from "./assets/services/s5.png";
import qa from "./assets/services/s6.png";
import web from "./assets/services/s7.png";
import ue from "./assets/services/s8.png";
import {motion} from "framer-motion";
import service_circle from '../client/assets/services-inner.png';
import service_right_bg from './assets/services-right.png';
import service_right_logo from './assets/service_logo.png';
import service_circle_rotate from './assets/service_circle_rotate.png';

const Services = () => {
    const [upperIndex, setIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const [animate, setAnimate] = useState(true);

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.only('md'));
    const isLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const isELarge = useMediaQuery(theme.breakpoints.only('xl'));
    const size = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    const servicesInfo = [
        {
            title: 'Game',
            titleT: 'Development',
            img: gd,
            details: 'Embark on a thrilling journey with Technotactics Software House as we delve into the realm of Game ' +
                'Development. Our seasoned developers fuse creativity with technical prowess to craft immersive gaming ' +
                'experiences. From conceptualizing compelling narratives to coding intricate game mechanics, we bring ' +
                'your ideas to life, ensuring every pixel and interaction captivates the player&apos;s imagination.'
        },

        {
            title: 'Metaverse',
            titleT: 'NFT',
            img: web,
            details: 'Technotactics pioneers the next frontier of the internet with our Web 3.0 services. We leverage ' +
                'decentralized networks and cutting-edge technologies to redefine online experiences. Whether it\'s ' +
                'creating interactive web applications, implementing blockchain solutions, or integrating AI seamlessly, ' +
                'we propel your digital presence into the future, where connectivity and intelligence converge to shape ' +
                'the next era of the web.'
        },

        {
            title: 'App Store',
            titleT: 'Optimization',
            img: ao,
            details: 'In the competitive landscape of mobile apps, Technotactics excels at App Store Optimization (ASO). ' +
                'Our experts meticulously optimize every aspect of your app, from crafting compelling descriptions to ' +
                'selecting strategic keywords and designing captivating visuals. ASO is not just about visibility; ' +
                'it\'s about understanding your audience and ensuring your app not only attracts downloads but also ' +
                'retains users with an engaging and user-friendly experience.',
        },

        {
            title: 'AR',
            titleT: 'VR',
            img: ar,
            details: 'Technotactics brings the magic of Augmented and Virtual Reality to life, where reality and ' +
                'imagination merge seamlessly. Our AR/VR specialists create immersive environments and interactive ' +
                'simulations that transcend conventional boundaries. Whether it\'s enhancing real-world experiences ' +
                'or crafting innovative training solutions, we harness the power of AR and VR to redefine how users ' +
                'engage with digital content.',
        },

        {
            title: 'Unreal Engine',
            img: ue,
            details: 'Experience the extraordinary with Technotactics Unreal Engine services. Our experts leverage the ' +
                'power of Unreal Engine to deliver visually stunning and highly interactive solutions. From game ' +
                'development to architectural visualization, our proficiency in Unreal Engine allows us to push the ' +
                'boundaries of what\'s possible, creating experiences that leave a lasting impact. Technotactics is ' +
                'your gateway to unlocking the full potential of this powerful game development engine.'
        },

        {
            title: 'Blockchain',
            img: ga,
            details: 'Step into the realm of dynamic storytelling with Technotactics Game Animation services. Our skilled ' +
                'animators breathe life into your games, creating fluid and captivating animations that enhance the ' +
                'overall gaming experience. Whether it\'s character animations, cinematic sequences, or interactive ' +
                'storytelling elements, our team ensures that every movement contributes to the immersive narrative, ' +
                'making your games visually stunning and emotionally engaging.',
        },

        {
            title: 'QA Testing',
            img: qa,
            details: 'At Technotactics Software House, we understand the importance of delivering flawless software, ' +
                'and our Quality Assurance (QA) Testing services are tailored to ensure just that. Our meticulous ' +
                'QA testers rigorously examine every aspect of your software, identifying and rectifying bugs, ' +
                'glitches, and performance issues. With our comprehensive testing methodologies, we guarantee a ' +
                'seamless user experience, allowing you to release software with confidence and reliability.',
        },
    ]

    const handleServiceClick = (index) =>{
        /*if (index === 0) {
            if(isMedium) {
                setLeft(8 + index * 135);
            } else if (isLarge) {
                setLeft(57 + index * 135);
            } else if (isELarge) {
                setLeft(67 + index * 135);
            }
        } else if (index > 0) {
            if(isMedium) {
                if(index === 1 || index === 2 || index === 3) {
                    setLeft(10 + index * 110 + index);
                } else if (index === 4) {
                    setLeft(10 + index * 110 + index + 2);
                } else if (index === 5) {
                    setLeft(10 + index * 110 + index + 3);
                } else if (index === 6) {
                    setLeft(10 + index * 110 + index + 4);
                }
            } else if (isLarge) {
                if(index === 1) {
                    setLeft(55 + index * 135 + index * 10);
                } else if (index === 2 || index === 3) {
                    setLeft(55 + index * 135 + index * 9);
                } else if (index === 4) {
                    setLeft(55 + index * 135 + index * 8.75);
                } else if (index > 4) {
                    setLeft(55 + index * 135 + index * 8.5);
                }
            } else if (isELarge) {
                setLeft(66 + index * 135 + index * 20);
            }*/
        setAnimate(false);
        const interval = setTimeout(()=> {
            setIndex(index);
            setAnimate(true);
        }, 1000);
        return () => clearTimeout(interval);
    }


    return(
        <Box id="services">
            <Stack width={'100%'} >
                <Box sx={{backgroundColor: '#000B10'}}>
                    <Box sx={mainOuterBox}>
                        <Stack width='100%' sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <Typography sx={bannerStyles.bannerText}>
                                Our Services
                            </Typography>
                        </Stack>
                    </Box>
                    <Stack width={'100%'} sx={{alignItems: 'center', justifyContent: 'center', backgroundColor: '#00131C', marginTop: '2rem', paddingY: '2rem'}}>
                        <Container maxWidth='xl' ref={ref} sx={{overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Box sx={{ maxWidth: { xs: '250px', sm: '550px', md: '800px', lg: '1150px', xl: '1500px' },display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {isVisible && (<Tabs
                                    value={upperIndex}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    TabIndicatorProps={{
                                        style:{
                                            backgroundColor: 'white',
                                            borderRadius: '5px',
                                            boxShadow: '0 0 10px 5px rgba(255,255,255,0.5)',
                                        }
                                    }}
                                    sx={{color: 'white'}}
                                >
                                    {servicesInfo.map((item, index) => (
                                        <motion.div
                                            initial={{opacity: 0, y: -100}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{type: 'slide', duration: 1, delay: index*0.25}}
                                            style={{margin: '0 1rem'}}
                                        >
                                            <Tab label={item.title} sx={{color: 'white', fontFamily: 'Treb'}} onClick={()=> handleServiceClick(index)}/>
                                        </motion.div>
                                    ))}
                                </Tabs>)}
                            </Box>
                        </Container>
                    </Stack>
                </Box>

                <Box sx={{backgroundColor: '#001823'}}>
                    <Container maxWidth={'xl'} sx={{position: 'relative'}}>
                        {size ? (<Box sx={circularDesign}/>) : <Box sx={circularDesignLarger} /> }
                        <Box sx={bottomStyles.upperBox}>
                            <Stack direction="column" sx={{height: 'inherit'}}>
                                <Stack direction={{xs: "column", md: "row"}} sx={{height: 'inherit'}}>
                                    <Box sx={{width: {xs: '100%', sm: '550px', md: '35%'}, marginX: {xs: 'auto', md: '0'}}}>
                                        <Stack sx={{padding: {md: '4.5rem 2rem 2rem 0', lg: '5.5rem 2rem 2rem 0'}, alignItems: 'start', justifyContent: {xs: 'start', md: 'center'}}}>
                                            {servicesInfo.map((item, index) =>(
                                                upperIndex === index ? (
                                                    <Slide direction={'right'} in={animate} mountOnEnter unmountOnExit timeout={1000}>
                                                        <Box>
                                                            <Typography sx={{...bottomStyles.titleDesign, color: 'var(--accent-col)'}}>{upperIndex === index && item.title}</Typography>
                                                            <Typography sx={bottomStyles.titleDesign}>{upperIndex === index && item.titleT}</Typography>
                                                            <Typography sx={{color: 'white', fontFamily: 'Treb'}}>{upperIndex === index && item.details}</Typography>
                                                        </Box>
                                                    </Slide>
                                                ) : <></>
                                            ))}
                                        </Stack>
                                    </Box>
                                    <Box sx={{width: {xs: '100%', md: '65%'}, padding: {xs: '3rem 0', md: '2rem 0'}, position: 'relative'}}>
                                        {isVisible && (<>
                                                {size ? (
                                                    <Slide direction="left" in={animate} timeout={1000}>
                                                        <Box sx={{width: '100%', position: 'relative', zIndex: 1}}>
                                                            <Box component='img' src={servicesInfo[upperIndex].img} alt={'img'} sx={rightStyles.characterStyleSmaller}/>
                                                        </Box>
                                                    </Slide>
                                                ) : (
                                                    <Slide direction="left" in={animate} timeout={1000}>
                                                        <Box component='img' src={servicesInfo[upperIndex].img} alt={'img'}
                                                             sx={rightStyles.characterStyleLarger}/>
                                                    </Slide>)}
                                                <Box sx={bottomStyles.rightBox}>
                                                    <Box component='img' src={service_right_bg} alt={'rightBox'} sx={{height: '100%', width: '100%'}}/>
                                                    <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit timeout={1000}>
                                                        <Box sx={{width: '100%', position: 'relative', zIndex: 0, bottom: {xs: 30, md: '90%'}}}>
                                                            {!size ? <Box component='img' src={service_circle} alt={'inner'} sx={rightStyles.innerCircle}/> : <Box component='img' src={service_circle_rotate} alt={'inner_circle'} sx={rightStyles.innerCircle}/>}
                                                        </Box>
                                                    </Slide>
                                                    <Slide direction="right" in={isVisible} mountOnEnter unmountOnExit timeout={1000}>
                                                        <Box component='img' src={service_right_logo} alt={'technotactics'} sx={rightStyles.logo}/>
                                                    </Slide>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Stack>
                                <Box sx={{width: '75%', height: '2px', backgroundColor: 'var(--text-unFocused)', marginX: 'auto', marginBottom: {xs: '2rem', md: '0'}}}/>
                            </Stack>
                        </Box>
                    </Container>
                </Box>
            </Stack>
        </Box>
    )
}

export default Services;