import React, {useEffect, useRef, useState} from 'react';
import {Box, Container, Slide, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {bottomRightStyles, mainStyles} from "./stylesheets/devProcessStyles";
import devprocess from "./assets/devprocess_bg.png";
import right_circle from './assets/process_half_circle.png';
import right_circle_top from './assets/process_half_circle-rotate.png';

const DevProcess = () => {
    const [Index, setIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const data = [
        {
            banner: 'Conceptualization',
            text: 'Embark on the creative journey with our Conceptualization phase, where ideas take shape and ' +
                'possibilities unfold. In this pivotal stage, we collaborate closely with our clients to define the ' +
                'vision, outline goals, and lay the foundation for the project. Beyond the placeholder of Lorem ' +
                'ipsum, we engage in meaningful discussions to understand your objectives, ensuring that every aspect ' +
                'of the project aligns with your vision before the final copy is crafted. Conceptualization sets the ' +
                'stage for an inspired and purposeful design process.',
        },

        {
            banner: 'Pre-Production',
            text: 'Transitioning from concept to execution, our Pre-Production phase is where meticulous planning and ' +
                'preparation come to the forefront. Here, the placeholder text is replaced with strategic actions, ' +
                'including script development, storyboarding, and detailed project planning. Our team works tirelessly ' +
                'to ensure that every element aligns seamlessly, setting the stage for a smooth and efficient production ' +
                'process. This phase is about laying the groundwork for success, leaving no room for uncertainties as ' +
                'we move closer to the final product.',
        },

        {
            banner: 'Production',
            text: 'As the project gains momentum, the Production phase becomes the epicenter of creativity and execution. ' +
                'This is the stage where tangible work by skilled professionals replaces placeholder text, bringing ' +
                'the envisioned project to life. Whether it involves graphic design, content creation, or coding, ' +
                'this phase is characterized by active development and hands-on work. With our dedicated team and ' +
                'cutting-edge tools, we transform concepts into reality, ensuring that every detail contributes to ' +
                'the overall excellence of the final product.',
        },

        {
            banner: 'Post-Production',
            text: 'In the Post-Production phase, our focus shifts to refinement and enhancement. This is the stage where ' +
                'we dedicate our efforts to polishing visual elements, fine-tuning details, and incorporating feedback. ' +
                'Meticulously reviewing and optimizing every aspect of the project, we ensure that it aligns perfectly' +
                ' with the envisioned outcome. This phase marks the transition from raw materials to a refined ' +
                'masterpiece, where every pixel and element is thoughtfully considered before the final product is ' +
                'ready for release.',
        },

        {
            banner: 'Release',
            text: 'The culmination of the creative journey arrives in the Release phase, where the placeholder text is ' +
                'replaced by the unveiling of the final product to the world. With careful attention to detail and a ' +
                'commitment to excellence, we present the completed project, ready to make an impact. This phase is' +
                ' about more than just delivering a product; it\'s about celebrating the journey, sharing the ' +
                'achievement, and ensuring that your vision becomes a reality that resonates with your audience.' +
                ' The release is not an endpoint but a launchpad for success.',
        },
    ]

    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    return(
        <Box id={'devprocess'} sx={{backgroundImage: `url(${devprocess})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', position: 'relative'}}>
            <Container ref={ref} sx={mainStyles.mainOuterContainer} maxWidth='xl'>
                <Stack direction={{xs: 'column-reverse', md: 'row'}} sx={{height: '100%', alignSelf: 'center', width: 'inherit', marginBottom: {xs: '0', md: '2rem'}}}>
                    <Stack sx={{width: {sm: '550px', md: '50%'}, marginX: {xs: 'auto', md: '0'}, minHeight: {md: '700px', lg: '650px', xl: '750px'}, height: 'auto', alignItems: 'start', justifyContent: 'end'}}>
                        <Slide direction={'right'} in={isVisible} timeout={1000}>
                            <Box sx={mainStyles.bottomBox}>
                                <Typography sx={mainStyles.subBannerText}>{data[Index].banner}</Typography>
                                <Typography sx={mainStyles.text}>{data[Index].text}</Typography>
                            </Box>
                        </Slide>
                    </Stack>
                    <Stack sx={{width: {xs: '100%', sm: '550px', md: '50%'}, marginX: {xs: 'auto', md: '0'}, minHeight: {xs: '500px', sm: '700px', md: '700px', lg: '650px', xl: '750px'}, height: 'auto', alignItems: 'start', justifyContent: 'space-evenly', paddingX: {md: '1rem', lg: '3rem'}}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '4rem 0 4rem 0'}}>
                            <Typography sx={mainStyles.upperBannerStyle}>
                                Why
                            </Typography>
                            <Typography sx={mainStyles.bannerStyle}>
                                Techno Tactics?
                            </Typography>
                        </Box>
                        <Box sx={bottomRightStyles.mainBox}>
                            {!isExtraSmall ? (<><Slide in={isVisible} timeout={1500} direction={'left'}><Box component='img' src={right_circle} alt={'circle'} sx={{height: '100%'}}/></Slide>
                            <Box sx={{height: '100%', width: '75%', right: 0, position: 'absolute', top: 0,}}>
                                {data.map((item, index) => (
                                    <Slide in={isVisible} timeout={1500} direction={index === 0 ? 'right' : index === 1 ? 'down' : index === 2 ? 'up' : index === 3 ? 'left' : 'right'}>
                                        <Box key={index} sx={{...bottomRightStyles.hexagonBox,
                                            top: (index === 0 ? '37.5%' : index === 1 ? '24%' : index === 2 ? '50.75%' : index === 3 ? '37.5%' : index === 4 ? '64%' : '0'),
                                            left: (index === 1 ? '28.5%' : index === 2 ? '28.5%' : index === 3 ? '57%' : index === 4 ? '57%' : '0'),
                                            backgroundColor: (index === Index ? 'var(--accent-col)' : 'var(--text-focused)')
                                        }} onClick={()=> setIndex(index)}>
                                            <Typography sx={{...bottomRightStyles.textStyle, paddingRight: (index === 0 ? '0.5rem' : '0.5rem'), backgroundColor: (Index === index ? 'rgba(255,255,255,0.75)' : 'transparent')}}>{item.banner}</Typography>
                                        </Box>
                                    </Slide>
                                ))}
                            </Box></>) :
                            <>
                            <Slide in={isVisible} timeout={1500} direction={'left'}><Box component='img' src={right_circle_top} alt={'circle'} sx={{height: '150px'}}/></Slide>
                            <Box sx={{height: '100%', width: '100%', right: 0, position: 'absolute', top: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', rowGap: '0.5rem'}}>
                                {data.map((item, index) => (
                                    <Slide in={isVisible} timeout={1500} direction={index === 0 ? 'right' : index === 1 ? 'down' : index === 2 ? 'up' : index === 3 ? 'left' : 'right'}>
                                        <Box key={index} sx={{...bottomRightStyles.hexagonBox,
                                            backgroundColor: (index === Index ? 'var(--accent-col)' : 'var(--text-focused)')
                                        }} onClick={()=> setIndex(index)}>
                                            <Typography sx={{...bottomRightStyles.textStyle, paddingRight: (index === 0 ? '0.5rem' : '0.5rem'), backgroundColor: (Index === index ? 'rgba(255,255,255,0.75)' : 'transparent')}}>{item.banner}</Typography>
                                        </Box>
                                    </Slide>
                                    ))}
                            </Box>
                            </>}
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default DevProcess;