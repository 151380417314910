import bg from '../assets/career-bg.png';

export const careersStyles = {
    mainOuterBox: {
        width: '100wv',
        height: {xs: 'auto', md: '100vh'},
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    stackDesign: {
        height: {xs: '60%',md: '85%'},
        width: '70%',
        marginX: 'auto',
        paddingBottom: '3rem',
        justifyContent: 'start',
        alignItems: 'center'
    },

    infoText: {
        color: 'black',
        marginTop: {xs: '2rem', md: '25%'},
        marginBottom: '2rem',
        fontSize: '20px',
        fontFamily: 'Treb'
    },

    leftStack: {
        justifyContent: 'center',
        alignItems: 'center',
    },

    btn: {
        backgroundColor: 'transparent',
        border: '1px solid #9D4726',
        paddingY: '0.75rem',
        paddingX: '3rem',
        borderRadius: '0px',
        marginTop: '2rem',
        fontSize: '15px',
        color: 'black',
        transition: '1s',
        '&:hover' : {
            backgroundColor: 'black',
            boxShadow: '0px 4px 8px rgba(157,71,38,0.75)',
            color: 'white',
        },
    },

    animationBox: {
        height: '100%',
    }
};