import bg from '../assets/testimonial_bg.png';
export const testimonialBox = {
    marginX: 'auto',
    width: {xs: '100vw', sm: '600px', md: '900px', lg: '1100px', xl: '1400px'},
};

export const bgBox = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

}

export const cardImage = {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    boxShadow: '0 0 5px rgba(61,43,96,0.75)',
    marginX: 'auto',
};

export const cardStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255,255,255,0.125)',
    backdropFilter: 'blur(10px)',
    padding: '1rem 1.5rem 1rem 1.5rem',
    marginY: '50px',
    height: {xs: 'auto', sm: '450px'},
    width: {xs: '70vw', sm: '300px', md: '275px', lg: '265px', xl: '325px'},
    cursor: 'grab',
    borderRadius: '25px',
    boxShadow: '-7px 10px 20px rgba(0,0,0,0.25)',
    transition: '0.5s',
}