import React, {useEffect, useState} from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {
    infoSectionStyles,
    mainLandingStyles,
    bannerStyles,
} from "./stylesheets/moreProjectsStyles";
import {useNavigate} from "react-router-dom";
import MMO from "./assets/MMO.png";
import football from "./assets/football.png";
import firefighter from "./assets/firefighter.png";
import NFT from './assets/NFT.png';
import ludo from './assets/ludo.png';
import color from './assets/coloring.png';
import city from './assets/city.png';
import warland from './assets/warland.png';
import Footer from "./Footer";
import Navbar from "./Navbar";
import bg from './assets/bg-vod.MP4'
import bgg from './assets/more-project-tbg.png';

const MoreProjects = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const data = [
        {
            title: 'NFT Duel',
            genre: 'Card Game',
            platform: 'Web 3.0/PC',
            engine: 'Unity',
            details: 'NFT Duel is a decentralized, blockchain-based game that utilizes non-fungible tokens (NFTs). ' +
                'Players can collect, trade, and battle with unique, digital collectibles on the Ethereum blockchain. ' +
                'In NFT Duel, players can choose from a variety of different characters, each with their own unique ' +
                'abilities and stats. Players can then use these characters to battle against one another to earn ' +
                'rewards and climb the leaderboards.',
            img: NFT,
        },

        {
            title: 'VR Fire Fighter Game',
            genre: 'simulation, multiplayer',
            platform: 'Oculus Quest/Desktop',
            engine: 'unity',
            details: 'A VR multiplayer project in which players have to extinguished the fire from the building. ' +
                'A full body VR IKis configured. Player can play as solo or as party. I used PUN 2 as an networking ' +
                'solution.',
            img: firefighter,
        },

        {
            title: 'Ludo',
            genre: 'Multiplayer/Arcade',
            platform: 'Android/iOS/Web 3.0',
            engine: 'Unity',
            details: 'Ludo is a classic multiplayer arcade game that brings timeless entertainment to players worldwide. ' +
                'Available on Android, iOS, and Web 3.0 platforms, it offers seamless cross-platform gameplay. Developed ' +
                'using Unity engine, Ludo combines intuitive controls with captivating graphics to provide an immersive ' +
                'gaming experience.',
            img: ludo,
        },

        {
            title: 'Warland',
            genre: 'Shooting/Action',
            platform: 'Android/iOS/PC',
            engine: 'Unity',
            details: 'Warland immerses players in intense shooting action, blending strategy and adrenaline-pumping combat. ' +
                'Available on Android, iOS, and PC platforms, it offers versatile gaming experiences across devices. ' +
                'Powered by Unity engine, Warland delivers stunning visuals and responsive gameplay. Prepare for an ' +
                'epic battle in a dynamic world where every move counts.',
            img: warland,
        },

        {
            title: 'Real Estate AR',
            genre: 'AR Simulation, Real Estate',
            platform: 'Web AR/Andoid/iOS',
            engine: 'Unity',
            details: 'Real Estate AR revolutionizes property exploration through immersive AR simulations. Combining ' +
                'the genres of AR simulation and real estate, it offers an innovative way to visualize properties. ' +
                'Accessible on Web AR, Android, and iOS platforms, users can experience virtual tours from anywhere. ' +
                'Developed with Unity engine, Real Estate AR delivers stunning visuals and realistic interactions ' +
                'for an unparalleled exploration experience.',
            img: city,
        },

        {
            title: 'Dach Coloring Book',
            genre: 'Kid Game',
            platform: 'Android/iOS',
            engine: 'Unity',
            details: 'A project in which player can color on images. User can use multiple brushes, pencils and ' +
                'paints. We implemented texture editing along with loading each texture from memory using addressable, ' +
                'Also used asset bundles and some optimization techniques.',
            img: color,
        },

        {
            title: 'VR MMO',
            genre: 'Multiplayer/VR/Action/Role Playing',
            platform: 'Desktop, Oculus Quest',
            engine: 'Unity',
            details: 'VR MMO immerses players in a thrilling multiplayer VR experience, blending action-packed gameplay ' +
                'with deep role-playing elements. Venture into a vast virtual world filled with adventure, challenges, ' +
                'and epic battles. Powered by Unity, this game delivers stunning visuals and seamless gameplay for an ' +
                'unforgettable gaming journey.',
            img: MMO,
        },

        {
            title: 'Join Soccer',
            genre: 'Hypercasual',
            platform: 'Android/iOS',
            engine: 'Unity',
            details: 'A one touch casual style runner game developer in Unity. Procedural level designed with multiple ' +
                'characters and themes, The player collect balls and also save them from hurdles. At last he kick ' +
                'all of them in to goal',
            img: football,
        }
    ]

    return(<Box sx={{overflow: 'hidden'}}>
        <Box>
            <Stack>
                <Box sx={mainLandingStyles.mainVideoBox}>
                    <Box sx={{height: '100vh', width: '100vw', zIndex: 1, background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 50%)', position: 'absolute', top: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Navbar props={"back"}/>
                        <Typography sx={mainLandingStyles.bannerText}>Our Projects</Typography>
                    </Box>
                    <Box sx={{height: '100vh', width: '100vw', zIndex: 0, opacity: '0.75', position: 'absolute', backgroundImage: `url(${bgg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}/>
                    <Box component={'video'} autoPlay muted loop sx={{width: '100vw', height: '100vh', objectFit: 'fill'}}>
                        <Box component={'source'} src={bg} type={'video/mp4'} sx={{height: '100%', width: '100%'}}/>
                    </Box>
                </Box>
            </Stack>

            <Stack width='80%' sx={{marginX: 'auto', alignItems: 'center', justifyContent: 'center', marginTop: '3rem'}}>
                <Typography sx={bannerStyles}>
                    Our Services
                </Typography>
            </Stack>

            <Typography sx={{...infoSectionStyles.detailsText, color: 'black', width: {xs: '90%', md: '75%', lg: '50%'}, marginX: 'auto', marginTop: '1.5rem'}}>
                Explore our portfolio of video games, where captivating gameplay and breathtaking graphics come
                together to create unforgettable adventures. From action-packed thrillers to immersive storytelling,
                our creations promise to leave a lasting impression on players worldwide.
            </Typography>

            <Stack sx={{marginTop: '2rem', marginBottom: '3rem'}}>
                <Grid container spacing={5}>
                    {data.map((Item, index) =>(<Grid key={index} item xs={12} sx={{height: '100%', filter: 'drop-shadow(5px 5px 15px rgba(0,0,0,1))'}}>
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{...infoSectionStyles.infoMainBox,position: 'relative'}}>
                            <Box sx={infoSectionStyles.internalBox}>
                                <Typography sx={infoSectionStyles.titleText}>{Item.title}</Typography>
                                <Box sx={infoSectionStyles.lineStyle}/>
                                <Typography sx={infoSectionStyles.infoBanner}>Genre: <Box component={"span"} sx={infoSectionStyles.infoText}>{Item.genre}</Box> </Typography>
                                <Typography sx={infoSectionStyles.infoBanner}>Platform: <Box component={"span"} sx={infoSectionStyles.infoText}>{Item.platform}</Box> </Typography>
                                <Typography sx={infoSectionStyles.infoBanner}>Engine: <Box component={"span"} sx={infoSectionStyles.infoText}>{Item.engine}</Box> </Typography>
                            </Box>
                            <Box sx={infoSectionStyles.detailsBox}>
                                <Typography sx={infoSectionStyles.infoBanner}>Details</Typography>
                                <Typography sx={infoSectionStyles.detailsText}>{Item.details}</Typography>
                            </Box>
                            <Box sx={{...infoSectionStyles.gradientBox,
                                background: (index === 0 ? 'linear-gradient(90deg, rgba(3,50,64,1) 0%, rgba(3,50,64,1) 50%, rgba(255,255,255,0) 100%)' :
                                        index === 1 ? 'linear-gradient(90deg, rgba(225,74,53,1) 0%, rgba(225,74,53,1) 50%, rgba(255,255,255,0) 100%)' :
                                            index === 2 ? 'linear-gradient(90deg, rgba(255,222,89,1) 0%, rgba(255,222,89,1) 50%, rgba(255,255,255,0) 100%)' :
                                                index === 3 ? 'linear-gradient(90deg, rgba(22,123,176,1) 0%, rgba(22,123,176,1) 50%, rgba(255,255,255,0) 100%)' :
                                                    index === 4 ? 'linear-gradient(90deg, rgba(219,179,125,1) 0%, rgba(219,179,125,1) 50%, rgba(255,255,255,0) 100%)' :
                                                        index === 5 ? 'linear-gradient(90deg, rgba(59,189,93,1) 0%, rgba(59,189,93,1) 50%, rgba(255,255,255,0) 100%)' :
                                                            index === 6 ? 'linear-gradient(90deg, rgba(228,54,191,1) 0%, rgba(228,54,191,1) 50%, rgba(255,255,255,0) 100%)' :
                                                                index === 7 ? 'linear-gradient(90deg, rgba(107,116,121,1) 0%, rgba(107,116,121,1) 50%, rgba(255,255,255,0) 100%)' : ''
                                )
                            }}/>
                            <Box component={'img'} src={Item.img} alt={Item.title} sx={infoSectionStyles.imgStyles}/>
                        </Stack>
                    </Grid>))}
                </Grid>
            </Stack>
        </Box>
        <Footer/>
    </Box>)
}

export default MoreProjects;