
export const mainOuterBox = {
    width: {md: '880px', lg: '1150px', xl: '1500px'},
    marginX: 'auto',
    maxWidth: '1500px',
    paddingTop: '2rem',
}

export const bannerStyles = {
    bannerText: {
        color: 'var(--text-focused)',
        textTransform : 'uppercase',
        fontFamily: 'Tommy',
        textAlign: 'center',
        fontWeight: '900',
        fontSize: {xs: '35px', sm: '50px', md: '40px', lg: '50px', xl: '55px'},
    }
};

export const servicesGridTabsStyles = {
    arrowCircle: {
        marginY: {xs: '1rem', md: '0'},
        backgroundColor: 'black',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        cursor: 'pointer',
        transition: '0.75s',
        '&:hover' : {
            backgroundColor: 'var(--pp-dark)',
            boxShadow: '0px 0px 8px rgba(61,43,96,0.9)'
        }
    },

    arrowIcon: {
        transform: {xs: 'rotate(90deg)', md: 'rotate(0deg)'},
        alignSelf: 'center',
        height: '30px',
        width: '30px',
        padding: '0.2rem',
    },

    tabBox: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '10px solid transparent',
        transition: '1s',
        '&:hover': {
            borderColor: 'var(--pp-dark)',
            boxShadow: '0px 0px 8px rgba(61,43,96,0.9)'
        }
    },

    selectedTabBox: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '10px solid var(--pp-dark)',
        transition: '1s',
        '&:hover': {
            boxShadow: '0px -8px 8px rgba(61,43,96,0.9)'
        }
    },

    tabs: {
        height: '50px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75%',
        transition: '1s',
        paddingX: '1rem',
        paddingY: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(120%)',
        },
    },
}

export const bottomStyles = {
    backgroundBox: {
        height: 'auto',
        width: '100%',
        position: 'absolute'
    },

    upperBox: {
        height: 'auto',
        width: '100%',
        position: 'relative',
        paddingY: {xs: '2rem', sm: 0},
    },

    rightBox: {
        position: 'relative',
        minHeight: {xs: '500px', sm: '700px', md: '400px', lg: '500px', xl: '600px'},
        height: {xs: '500px', sm: '650px', md: '400px', lg: '500px', xl: '600px'},
        width: {xs: '100%', sm: '550px', md: '100%'},
        marginX: {xs: 'auto', md: '0'},
    },

    titleDesign: {
        color: 'white',
        fontSize: {xs: '35px', sm: '50px', md: '40px', lg: '50px', xl: '60px'},
        fontWeight: '900',
        fontFamily: 'Tommy',
        textTransform: 'uppercase'
    }
}

export const circularDesign = {
    position: 'absolute',
    clipPath: 'circle(50.0% at 85% 50%)',
    backgroundColor: 'var(--accent-col)',
    height: {xs: '350px', sm: '450px', md: '275px', lg: '350px', xl: '400px'},
    width: {xs: '200px', sm: '250px', md: '275px', lg: '350px' ,xl: '400px'},
    right: {xs: '', md: '0'},
    left: {xs: '50%', md: ''},
    top: {md: '95px', lg: '110px', xl: '135px'},
    bottom: {xs: '-10px',sm: '-66px', md: ''},
    transform: {xs: 'translate(-50%, 0) rotate(90deg)', md: null},
}

export const circularDesignLarger = {
    position: 'absolute',
    clipPath: 'circle(50.0% at 85% 50%)',
    backgroundColor: 'var(--accent-col)',
    height: {md: '275px', lg: '350px', xl: '400px'},
    width: {md: '275px', lg: '350px' ,xl: '400px'},
    right: '0',
    top: {md: '95px', lg: '110px', xl: '135px'},
}

export const rightStyles = {
    characterStyleSmaller: {
        position: 'absolute',
        height: {xs: '400px', sm: '550px'},
        bottom: {xs: '-500px', sm: '-700px'},
        left: '50%',
        zIndex: 1,
        transform: 'translate(-50%, 0)',
    },

    characterStyleLarger: {
        position: 'absolute',
        height: {md: '450px', lg: '550px', xl: '650px'},
        top: '0',
        right: '10%',
        zIndex: 1,
    },

    innerCircle: {
        height: {xs: '250px', sm: '300px', md: '275px',lg: '350px', xl: '400px'},
        width: {xs: '250px', sm: '300px', md: '275px', lg: '350px', xl: '400px'},
        position: 'absolute',
        top: {xs: '', md: '10%'}, bottom: {xs: '10%', md: ''},
        right: {xs: '5%', sm: '22%', md: '10%'},
    },

    logo: {
        height: {xs: '75px' ,sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        width: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        position: 'absolute',
        left: {xs: '', md: '5%'},
        right: {xs: '5%', md: ''},
        top: '5%'
    },
}