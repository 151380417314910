
export const mainLandingStyles = {
    mainVideoBox: {
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
    },

    bannerText: {
        color: 'var(--text-focused)',
        textTransform : 'uppercase',
        fontFamily: 'Tommy',
        textAlign: 'center',
        fontWeight: '900',
        fontSize: {xs: '35px', sm: '50px', md: '40px', lg: '50px', xl: '55px'},
    }
}

export const infoSectionStyles = {
    infoMainBox: {
        height: '100%',
        width: '80%',
        marginX: 'auto',
        clipPath: 'polygon(5% 0%, 100% 0, 100% 90%, 95% 100%, 0 100%, 0 10%)',
    },

    internalBox: {
        height: '100%',
        width: {xs: '75%', md: '50%'},
        padding: {xs: '2rem 0.5rem 2rem 2rem',md: '3rem 0.5rem 3rem 3rem', xl: '5rem 0 5rem 7.5rem'},
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
    },

    titleText: {
        color: 'var(--text-focused)',
        fontSize: '40px',
        fontWeight: 'bold',
        fontFamily: 'Tommy'
    },

    lineStyle: {
        height: '2px',
        width: '50px',
        backgroundColor: 'white',
        marginY: '1rem',
    },

    infoBanner: {
        color: 'white',
        fontSize: {xs: '20px', sm: '25px', lg: '30px'},
        fontWeight: 'bold',
        fontFamily: 'Tommy',
    },

    infoText: {
        color: 'white',
        fontSize: '22px',
    },

    detailsText: {
        color: 'var(--text-focused)',
        fontSize: {xs: '16px', md: '18px', lg: '19px', xl: '20px'},
        marginTop: '0.5rem',
        fontFamily: 'Treb',
    },

    outerHexaBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%)',
        width: '300px',
        height: '73px',
        transition: '0.75s',
        marginTop: '3rem',
        '&:hover' : {
            backgroundColor: 'red',
        }
    },

    detailsBox: {
        height: '100%',
        width: {xs: '75%', md: '50%'},
        paddingX: {xs: '2rem',md: '3rem', xl: '7.5rem'},
        paddingY: '2rem',
    },

    imgStyles: {
        height: '100%',
        width: {xs: '100%', md: '50%'},
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -2,
    },

    gradientBox: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: -1,
    }
};

export const bannerStyles = {
    color: 'var(--primary-col)',
    textTransform : 'uppercase',
    fontFamily: 'Tommy',
    textAlign: 'center',
    fontWeight: '900',
    fontSize: {xs: '35px', sm: '50px', md: '40px', lg: '50px', xl: '55px'},
};

export const backBtn = {
    position: 'fixed',
    top: '5rem',
    left: '3.5rem',
    backgroundColor: 'red',
    height: '40px',
    width: '40px',
    paddingX: '0.5rem',
    paddingY: '0.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    zIndex: 10,
    transition: '1s',
    '&:hover': {
        borderRadius: '15px',
        width: '100px',
    }
};

export const iconStyle = {
    height: '40px',
    width: '40px',
    color: 'white',
    transition: '0.5s',
};

export const iconRotate = {
    transform: 'rotate(360deg)'
}

export const collapseBox = {
    color: 'white',
    fontSize: '20px',
};
