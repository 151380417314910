import React, {useEffect, useRef, useState} from 'react';
import {Box, Grow} from "@mui/material";
import Navbar from "./Navbar";
import Header from "./Header";
import Services from "./Services";
import Contact from "./Contact";
import Careers from "./Careers";
import Portfolio from "./Portfolio";
import Testimonial from "./Testimonial";
import DevProcess from "./DevProcess";
import Footer from "./Footer";
import About from "./About";
import lottie from "lottie-web";
import logo from './assets/logo-light.webp';

const App = () => {
    const container = useRef(null);
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!animationLoaded) {
            lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('./assets/loading-anim.json'),
            })
            setAnimationLoaded(true);
        }
    }, [animationLoaded]);

    const handleAnim = () => {
        const timer = setTimeout(() => {
            setLoading(true);
        }, 2000);

        return ()=> clearTimeout(timer);
    }

    return(
        <Box>
            {/*!loading && (
                <Box sx={{height: '100vh', width: '100vw', backgroundColor: 'var(--primary-col)', position: 'absolute', zIndex: 2, overflow: 'hidden'}}>
                    <Box sx={{position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100vw', flexDirection: 'column'}}>
                        <Grow in={true} {...({timeout: 2000})}><Box component="img" src={logo} alt={'brandy'} sx={{height: '150px', width: '100px', marginBottom: '-125px'}}/></Grow>
                        <Box sx={{height: '300px', width: '400px'}} className="container" ref={container}/>
                    </Box>
                </Box>
                )*/}
            <Box onLoad={handleAnim} sx={{position: 'absolute', zIndex: 1, height: (!loading ? '100vh' : 'auto'), overflow: 'hidden'}}>
                <Navbar/>
                <Header/>
                <Services />
                <About />
                <Portfolio/>
                <DevProcess />
                <Testimonial/>
                <Contact />
                <Careers />
                <Footer />
            </Box>
        </Box>
    )
}

export default App;