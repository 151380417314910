import bg from '../assets/header-bg.webp';

export const headerMainBox = {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: {xs: 'auto', md: '100vh'},
    minHeight: {md: '600px', lg: '700px', xl: '800px'},
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const mainStack = {
    width: {xs: '90%', sm: '550px', md: '850px', lg: '1150px', xl: '1500px'},
    maxWidth: '1500px',
    marginX: 'auto',
    height: {xs: 'auto', md: '500px', lg: '600px', xl: '90%'},
    alignSelf: 'end'
}

export const infoStack = {
    width: {xs: '100%', md: '30%'},
    position: {xs: 'inline'},
    margin: {xs: '80px 0 1rem 0', md: '5rem 0rem 5rem 2rem'},
    justifyContent: 'start',
    alignItems: {xs: 'start', md: 'start'},
    zIndex: 2,
    columnGap: {xs: '0', md: '2rem'},
    rowGap: {xs: '2rem', md: '25vh'},
};

export const infoText = {
    position: 'relative',
    textAlign: {xs: 'start', md: 'start'},
    fontSize: {xs: '50px', md: '36px', lg: '49px', xl: '60px'},
    color: 'var(--text-focused)',
    fontWeight: '900',
    fontFamily: 'Tommy',
    textTransform: 'uppercase',
    '&::before': {
        content: '""',
        height: {xs: '30px', sm: '35px', md: '26px', lg: '35px', xl: '40px'},
        width: {xs: '9px', sm: '10px', md: '8px', lg: '10px', xl: '12px'},
        backgroundColor: 'var(--accent-col)',
        display: 'block',
        position: 'absolute',
        borderRadius: '10px',
        left: {xs: '15px', sm: '4.4%', md: '4%', lg: '14.5px', xl: '4.5%'},
        top: {xs: '10px', sm: '6%', md: '3%', lg: '4%', xl: '8.5%'}
    }
};

export const infoTextSecondary = {
    position: 'relative',
    textAlign: {xs: 'start', md: 'start'},
    fontSize: {xs: '50px', md: '36px', lg: '49px', xl: '60px'},
    color: 'var(--text-focused)',
    fontWeight: '900',
    fontFamily: 'Tommy',
    textTransform: 'uppercase',
};

export const borderCircleBox = {
    height: {xs: '50vh', sm: '550px', md: '400px', lg: '500px',xl: '600px'},
    width: {xs: '275px', sm: '550px', md: '400px', lg: '500px',xl: '600px'},
}

export const outerHexaBox = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '250px',
    height: '50px',
    transition: '0.75s',
    '&:hover' : {
        backgroundColor: 'white',
    }
};

export const contactBtn = {
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    fontSize: {xs: '17px', md: '20px'},
    alignItems: 'center',
    fontFamily: 'Noir',
    width: {xs: '200px', md: '240px'},
    height: {xs: '15px', md: '20px'},
    paddingY: '1rem',
    borderRadius: '7px',
    background: 'linear-gradient(90deg, rgba(251,40,52,1) 0%, rgba(255,16,24,1) 100%)',
    cursor: 'pointer',
    transition: '0.75s',
    border: '2px solid #D11018'
};

export const characterBox = {
    position: 'absolute',
    width: {xs: '275px', sm: '400px', md: '450px', lg: '550px', xl: '650px'},
    height: {xs: '100%', sm: '575px', md: '95%', lg: '100%'},
    bottom: 0,
    right: {sm: '0', md: '4%', lg: '6%', xl: '12.5%'},
}