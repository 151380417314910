import bg from '../assets/contact_bg-removebg.png';

export const mainBGBOX = {
    width: '100%',
    backgroundColor: 'var(--primary-col)',
};

export const contactTextLabel = {
    color: 'var(--text-focused)',
    fontWeight: '700',
    fontSize: {xs: '18px', sm: '20px', md: '20px', lg: '23px', xl: '25px'},
    marginBottom: '0.5rem',
    fontFamily: 'Treb'
};

export const mainContainerGrid = {
    width: {xs: '80%', md: '60%'},
    maxWidth: '800px',
    marginX: 'auto',
    paddingY: '3rem',
};

export const buttonBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'drop-shadow(0px 0px 10px rgba(0,0,0,0.75))',
    height: '55px',
    width: '100%',
    cursor: 'pointer',
    clipPath: 'polygon(5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%, 0% 50%)',
    backgroundColor: 'transparent',
    transition: '0.75s',
    '&:hover': {
        backgroundColor: 'red',
        filter: 'drop-shadow(0px 0px 10px rgba(0,0,0,0.75))',
    }
}