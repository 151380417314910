

export const mainStyles = {
    mainOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
    },

    lineDesign: {
        clipPath: 'polygon(100% 0, 100% 15%, 48% 100%, 30% 100%, 90% 0)',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: '#001823',
    },

    logoStyles: {
        height: {xs: '50px', md: '50px', lg: '75px', xl: '90px'},
        width: {xs: '50px', md: '50px', lg: '75px', xl: '90px'},
        position: 'absolute',
        top: '10%',
        right: '4%',
    },

    logoRight: {
        height: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        width: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
    },

    bannerStyle: {
        position: 'relative',
        textAlign: {xs: 'center', md: 'start'},
        fontSize: {xs: '45px', md: '32px', lg: '43px', xl: '60px'},
        color: '#001823',
        fontWeight: '900',
        textTransform: 'uppercase',
        '&::before': {
            content: '""',
            height: '40px',
            width: '12px',
            backgroundColor: 'var(--accent-col)',
            display: 'block',
            position: 'relative',
            borderRadius: '10px',
            left: '32.25%',
            top: '37%'
        }
    },

    bottomBox: {
        padding: {xs: '2rem', sm: '2rem 4rem'},
        boxShadow: '-20px 20px 15px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(255,255,255,0.125)',
        borderRadius: '45px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backdropFilter: 'blur(5px)',
    },

    subBannerText: {
        color : '#001823',
        fontSize: {xs: '25px', sm: '45px',md: '25px', lg: '30px', xl: '35px'},
        fontFamily: 'Tommy',
        fontWeight: '900',
        textTransform: 'uppercase',
        marginBottom: '0.5rem'
    },

    text: {
        color: 'var(--text-unFocused)',
        fontSize: {xs: '16px', md: '18px', lg: '19px', xl: '20px'},
        marginTop: '0.5rem',
        marginRight: {md: '3rem', xl: '7.5rem'},
        fontFamily: 'Treb',
    },

    gameBox: {
        width: 'calc(50% - 1rem)',
        height: {xs: '200px', sm: '275px', md: '250px', lg: '275px', xl: '300px'},
        borderRadius: '35px',
        boxShadow: '-20px 20px 15px rgba(0,0,0,0.25)',
    },

    rightGameBox: {
        width: {xs: '100%', md: '50%'},
        maxWidth: {xs: '467px',sm: '550px', md: '100%'},
        marginX: {xs: 'auto', md: '0'},
        height: {xs: '300px', sm: '450px', md: '400px', lg: '450px', xl: '500px'},
        borderRadius: '35px',
        boxShadow: '-20px 20px 15px rgba(0,0,0,0.25)',
    }
}

export const bannerStyles = {
    bannerText: {
        color: 'var(--text-focused)',
        textAlign: 'center',
        fontWeight: '900',
        fontSize: {xs: '35px', sm: '40px', md: '40px', lg: '50px', xl: '60px'},
        fontFamily: 'Tommy',
        textTransform: 'uppercase'
    }
};

export const subText = {
    fontWeight: '600',
    textAlign: 'center',
    textWrap: 'no-wrap',
    fontSize: {xs: '18px', sm: '25px', md: '30px', lg: '40px', xl: '40px'},
    fontFamily: 'Tommy',
    backgroundColor: 'black',
    color: 'var(--text-focused)',
    width: {xs: '75%', md: 'auto'},
    marginX: 'auto',
    padding: {xs: '1rem', md: '1rem 4rem'},
    borderRadius: '50px',
    marginBottom: '1.5rem',
    paddingTop: '1.15rem'
};

export const contactBtn = {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Noir',
    fontSize: {xs: '15px', sm: '18px'},
    alignItems: 'center',
    width: {xs: '180px', sm: '220px'},
    height: '20px',
    paddingY: '0.75rem',
    borderRadius: '7px',
    background: 'linear-gradient(90deg, rgba(251,40,52,1) 0%, rgba(255,16,24,1) 100%)',
    cursor: 'pointer',
    transition: '0.75s',
    border: '2px solid #D11018'
};