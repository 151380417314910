import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Slide, Stack, Typography} from "@mui/material";
import {careersStyles} from "./stylesheets/careersStyles";
import lottie from 'lottie-web';
import {contactBtn} from "./stylesheets/headerStyles";
import {bannerStyles} from "./stylesheets/portfolioStyles";
import {motion} from 'framer-motion';

const Careers = () => {
    const container = useRef(null);
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);


    useEffect(() => {
        if(!animationLoaded) {
            lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('./assets/careers_animation.json'),
            })
            setAnimationLoaded(true);
        }
    }, [animationLoaded]);

    const handleMailRequest = () => {
        const email = 'support@technotactics.com';
        const subject = 'Application for Job';

        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}`;

        const newWindow = window.open(gmailUrl, '_blank');

        if (newWindow) {
            newWindow.focus();
        } else {
            alert('Please Allow Popups to Open Gmail.');
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);


    return(<Box id={'careers'}>
        <Box sx={careersStyles.mainOuterBox}>
            <Stack ref={ref} sx={careersStyles.stackDesign}>
                <Typography sx={{...bannerStyles.bannerText, color: 'var(--accent-col)', paddingY: '2rem'}}>
                    Careers
                </Typography>
                <Grid container sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', rowGap: {xs: '2rem', sm: '3rem', md: '0'}}}>
                    <Grid item xs={12} md={6}>
                        <Slide direction="right" in={isVisible} timeout={1500}>
                            <Stack width={'100%'} sx={careersStyles.leftStack}>
                                <Typography sx={careersStyles.infoText}>Interested in Careers with us?</Typography>
                                <Box sx={{margin: '0 auto 0 auto', width: 'auto', height: '50px'}}>
                                    <motion.div
                                        whileHover={{scale: 1.15}}
                                        whileTap={{scale: 0.9}}
                                        transition={{type: "spring", stiffness: 400, damping: 17}}
                                    >
                                        <Typography sx={contactBtn} onClick={()=> handleMailRequest()}>
                                            Apply Now
                                        </Typography>
                                    </motion.div>
                                </Box>
                            </Stack>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Slide direction="left" in={isVisible} timeout={1500}><Box sx={careersStyles.animationBox} className="container" ref={container}/></Slide>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Box>)
}

export default Careers;