
export const mainStyles = {
    mainOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
    },

    upperBannerStyle: {
        position: 'relative',
        textAlign: {xs: 'start', sm: 'start', md: 'start'},
        fontSize: {xs: '35px', sm: '45px', md: '32px', lg: '43px', xl: '60px'},
        fontFamily: 'Tommy',
        color: 'var(--accent-col)',
        fontWeight: '900',
        textTransform: 'uppercase',
    },

    bannerStyle: {
        position: 'relative',
        textAlign: {xs: 'start', md: 'start'},
        fontSize: {xs: '35px', sm: '45px', md: '32px', lg: '43px', xl: '60px'},
        fontFamily: 'Tommy',
        color: 'var(--text-focused)',
        fontWeight: '900',
        textTransform: 'uppercase',
        '&::before': {
            content: '""',
            height: {xs: '25px', sm: '30px', md: '20px', lg: '30px', xl: '40px'},
            width: {xs: '8px', sm: '10px', md: '8px', lg: '10px', xl: '12px'},
            backgroundColor: 'var(--accent-col)',
            display: 'block',
            position: 'absolute',
            borderRadius: '10px',
            left: {xs: '124px', sm: '159px', md: '113px', lg: '152px', xl: '213px'},
            top: {xs: '3px', sm: '5px'}
        }
    },

    bottomBox: {
        padding: {xs: '2rem 2rem', md: '2rem 4rem'},
        boxShadow: '10px 20px 15px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(0,24,35,0.125)',
        borderRadius: '45px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backdropFilter: 'blur(5px)',
        marginY: {xs: '2rem', md: '4rem'}
    },

    subBannerText: {
        color : 'var(--text-focused)',
        fontSize: {xs: '22px', sm: '45px', md: '25px', lg: '30px', xl: '35px'},
        wordBreak: 'break-all',
        fontWeight: '900',
        textTransform: 'uppercase',
        marginBottom: '0.5rem',
        fontFamily: 'Tommy'
    },

    text: {
        color: 'var(--text-unFocused)',
        fontSize: {xs: '16px', md: '18px', lg: '19px', xl: '20px'},
        marginTop: '0.5rem',
        fontFamily: 'Treb',
    },
}

export const bottomRightStyles = {
    mainBox: {
        height: {xs: '575px', sm: '525px', md: '350px', lg: '450px', xl: '525px'},
        width: {xs: '260px', sm: '575px', md: '400px', lg: '500px', xl: '575px'},
        marginX: {xs: 'auto', sm: '0'},
        position: 'relative',
        display: {xs: 'flex', sm: 'inline'},
        flexDirection: {xs: 'column', md: null}
    },

    hexagonBox: {
        clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
        height: {xs: '90px', sm: '135px', md: '90px', lg: '115px',xl: '135px'},
        width: {xs: '108px', sm: '155px', md: '108px', lg: '135px',xl: '155px'},
        backgroundColor: 'var(--text-focused)',
        position: {xs: null,sm: 'absolute'},
        top: '37.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        transition: '0.5s',
        cursor: 'pointer'
    },

    textStyle: {
        textAlign: 'center',
        paddingRight: '0.25rem',
        paddingTop: '0.25rem',
        paddingLeft: '0.5rem',
        wordBreak: 'break-all',
        fontSize: {xs: '10px', sm: '15px', md: '10px', lg: '13px', xl: '15px'},
        fontWeight: 'bold',
        height: '20%',
        backgroundColor: 'transparent',
        color: '#001823',
        borderRadius: '15px 0 0 15px',
        transition: '0.5s'
    }
}
