
export const borderMainNavBox = {
    backgroundColor: 'transparent',
    marginX: 'auto',
    height: '100px',
    maxWidth: '1200px',
    width: {xs: '100%', sm: '575px', md: '800px', lg: '1100px', xl: '1200px'},
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const buttonsMainBox = {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    '&::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '2px',
        backgroundColor: 'var(--text-unFocused)',
    },
    '&::before': {
        display: 'block',
        content: '""',
        height: '6px',
        width: '135px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px 5px rgba(255,255,255,0.5)',
        borderRadius : '2px',
        position: 'absolute',
        bottom: '36px',
        left: '60px',
        transition: '0.5s',
    }
};

export const buttonsBox = {
    width: '90%',
    marginX: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const navButton = {
    wordBreak: 'keep-all',
    textTransform: 'uppercase',
    minWidth: '90px',
    width: '103px',
    maxWidth: '103px',
    height: '50px',
    fontSize: {md: '15px', lg: '15px'},
    textAlign: 'center',
    color: 'var(--text-unFocused)',
    paddingY: '0.25rem',
    marginX: {md: '10px', lg: '20px', xl: '25px'},
    fontWeight: '600',
    display: 'flex',
    border: '0.5px solid transparent',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transform: 'scale(90%)',
    transition: '0.35s ease-in-out',
    '&:hover' : {
        color: 'white',
    },
};

export const navButtonUnSelected = {
    wordBreak: 'keep-all',
    textTransform: 'uppercase',
    minWidth: '90px',
    width: '100px',
    textAlign: 'center',
    maxWidth: '100px',
    height: '50px',
    fontSize: {md: '15px', lg: '15px'},
    color: 'white',
    paddingY: '0.25rem',
    marginX: {md: '10px', lg: '15px', xl: '25px'},
    fontWeight: '600',
    display: 'flex',
    border: '0.5px solid transparent',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.35s ease-in-out',
}

export const iconStyle = {
    height: {xs: '70px', sm: '50px', md: '65px'},
    width: {xs: '55px', sm: '50px', md: '65px'},
}

export const navbarLogo = {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '150px',
    minWidth: '100px',
    width: '100px',
    maxWidth: '100px',
};

export const drawerStyles = {
    mainOuterBox: {
        padding: '0.5rem',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 24, 36, 0.5)',
        backdropFilter: 'blur(5px)',
    },

    drawerSX: {
        width: '90%',
        marginY: '10px',
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'rgba(0, 24, 36, 0.5)',
        backdropFilter: 'blur(5px)',
        boxShadow: '0 0 3px rgba(255, 29, 40, 0.75)',
        paddingY: '1rem',
    },

    listStyle: {
        textAlign: 'start',
        color : '#FFF',
        width: '100%',
        fontFamily: 'Treb'
    },

    tabIconStyle: {
        color: '#FFF',
        marginRight: '1rem',
    },

    navbarLogo: {
        height: '65px',
        width: {xs: '150px', md: '500px'},
        backgroundColor: 'transparent',
    },
}