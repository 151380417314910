
export const upperStyles = {
    upperMainBox: {
        width: '100%',
        backgroundColor: 'var(--primary-col)',
        paddingBottom: '5rem',
        paddingTop: '3rem'
    },

    mainStack: {
        width: {xs: '100%', md:'75%'},
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '2rem',
    },

    iconsStyle: {
        height: '30px',
        width: '30px',
        color: 'white'
    },

    infoText: {
        fontSize: '20px',
        color: 'var(--text-focused)',
        fontFamily: 'Treb',
    },

    footerLogo: {
        height: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        width: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
    },
}

export const bottomStyles = {
    bottomMainBox: {
        width: '100%',
        background: 'var(--accent-col)',
        paddingY: '2rem',
    },

    mainStack: {
        width: '75%',
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
    },

    iconOuterBox: {
        height: '40px',
        width: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        boxShadow: '0 0 10px rgba(255,255,255,0.75)',
        cursor: 'pointer',
    },

    linkStyle: {
        color: 'white',
        fontSize: {xs: '18px', md: '20px'},
        fontFamily: 'Treb',
        underline: '2px solid black',
        textAlign: 'center',
        paddingX: '1rem',
        paddingY: '2rem',
        cursor: 'pointer',
        transition: '0.25s',
        '&: hover': {
            color: 'var(--primary-col)'
        }
    }
}