import {Box, Container, Slide, Stack, Typography} from "@mui/material";
import {mainOuterBox, bannerStyles, mainStyles} from "./stylesheets/aboutStyles";
import React, {useEffect, useRef, useState} from "react";
import lottie from "lottie-web";
import about from "./assets/about-bg.png";
import icon from './assets/service_logo.png';
import character from './assets/about_character-1.png';

const About = () => {
    const container = useRef(null);
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if(!animationLoaded) {
            lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('./assets/about-animation.json'),
            })
            setAnimationLoaded(true);
        }
    }, [animationLoaded]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);


    return(
        <>
            <Stack id={'about'}>
                <Box sx={{backgroundColor: '#001823'}}>
                    <Box sx={mainOuterBox}>
                        <Stack direction={{xs: 'column', md: 'row'}} width='100%' sx={{alignItems: 'center', justifyContent: 'center', color: 'white'}}>
                            <Typography sx={bannerStyles.bannerText}>
                                About Us
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
                <Box ref={ref} sx={{backgroundImage: `url(${about})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', position: 'relative'}}>
                    <Box sx={mainStyles.lineDesign}/>
                    <Box component='img' src={icon} alt={'icon'} sx={mainStyles.logoStyles}/>
                    <Container sx={mainStyles.mainOuterContainer} maxWidth='xl'>
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{height: '100%', alignSelf: 'center', width: 'inherit'}}>
                            <Stack sx={{width: {xs: '100%', md: '55%'}, minHeight: {md: '700px', lg: '650px', xl: '750px'}, height: {xs: 'auto', md: '90vh'}, alignItems: 'start', justifyContent: 'space-evenly', marginTop: {xs: '6rem', md: '0'}, rowGap: '2rem'}}>
                                <Slide direction={"down"} in={isVisible} timeout={1000} mountOnEnter unmountOnExit><Typography sx={mainStyles.bannerStyle}>About <Box component='span' sx={{color: 'var(--accent-col)'}}>Us</Box></Typography></Slide>
                                <Slide direction={"right"} in={isVisible} timeout={1000} mountOnEnter unmountOnExit><Box sx={mainStyles.bottomBox}>
                                    <Typography sx={mainStyles.subBannerText}>Where Gaming Evolves!</Typography>
                                    <Typography sx={mainStyles.text}>At TechnoTactics, we are just not passionate about computer games. We live and breathe the digital realm. As a dynamic organization dedicated to pushing the boundaries of gaming. We have embarked on a journey to redefine the gaming experience.
                                    Our commitment lies in the seamless fusion of the cutting edge technology, immersive gameplay, and a community driven ethos that forms the very foundation of Techno Tactics</Typography>
                                </Box></Slide>
                            </Stack>
                            <Box sx={{width: {xs: '100%', md: '45%'}, minHeight: {sm: '650px', md: '700px', lg: '650px', xl: '750px'}, height: {xs: 'auto', md: '90vh'},display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                                <Slide direction={"left"} in={isVisible} timeout={1000} mountOnEnter unmountOnExit><Box component='img' sx={mainStyles.rightImage} src={character} alt={'character'}/></Slide>
                            </Box>
                        </Stack>
                    </Container>
                </Box>
            </Stack>
        </>
    )
}

export default About;