import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./client/App";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MoreProjects from "./client/MoreProjects";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename={window.location.pathname || ''}>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/MoreProjects" element={<MoreProjects />} />
        </Routes>
    </BrowserRouter>
);
