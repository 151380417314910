import React, {useEffect, useRef, useState} from 'react';
import {Box, Slide, Stack, Typography} from "@mui/material";
import {
    borderCircleBox, characterBox,
    contactBtn,
    headerMainBox,
    infoStack,
    infoText, infoTextSecondary, mainStack,
} from "./stylesheets/headerStyles";
import {motion} from 'framer-motion';
import character from './assets/header-character.png';
import character_1_bg from './assets/header-1-char-bg.png';
import character_2 from './assets/header-character-2.png';
import character_2_bg from './assets/header-2-char-bg.png';

const Header = () => {
    const [index, setIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const images = [character, character_2];
    const bg_images = [character_1_bg, character_2_bg];

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(true);
            const internal = setTimeout(() => {
                setIsVisible(false);
            }, 3000)
            setIndex((index + 1) % images.length);
            return ()=> clearTimeout(internal);
        }, 3000);
        return () => clearInterval(interval);
    }, [index, images]);

    const handleScroll = () => {
        const element = document.getElementById("contact");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    return(
        <Box id={'home'}>
            <Box ref={ref} id={'mainBox'} sx={headerMainBox}>
                <Stack direction={{xs: 'column', md: 'row'}} sx={mainStack}>
                    <Stack sx={infoStack}>
                        <Box>
                            <Slide direction={'right'} in={true} timeout={1500} style={{transitionDelay: '0ms'}}>
                                <Typography sx={infoText}>Onwards To</Typography>
                            </Slide>
                            <Slide direction={'right'} in={true} timeout={1700} style={{transitionDelay: '0ms'}}>
                                <Typography sx={infoTextSecondary}>Glory!</Typography>
                            </Slide>
                        </Box>
                        <motion.div
                            initial={{x: '-100vw'}}
                            animate={{x: 0}}
                            transition={{type: 'slide', duration: 1.5}}
                        >
                            <motion.div
                                transition={{type: 'spring', stiffness: 400, damping: 12}}
                                whileHover={{scale: 1.15, type: 'spring'}}
                                whileTap={{scale: 0.9}}>
                                <Typography sx={contactBtn} onClick={handleScroll}>
                                    Request a Quote
                                </Typography>
                            </motion.div>
                        </motion.div>
                    </Stack>
                    <Slide direction={'left'} in={isVisible} timeout={1500}>
                        <Box sx={{
                            width: {xs: '100%', md: '70%'},
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <Box sx={index === 0 ? {...borderCircleBox} : {...borderCircleBox, height: {xs: '50vh', sm: '550px', md: '400px', lg: '500px',xl: '575px'}}} src={bg_images[index]} alt={'bg'} component={'img'}/>
                            <Box component={'img'} sx={index === 0 ? {...characterBox} : {...characterBox, height: {xs: '100%', sm: '575px', md: '625px', lg: '90%'}}} src={images[index]} alt={'character'}/>
                        </Box>
                    </Slide>
                </Stack>
            </Box>
        </Box>
    )
}

export default Header;