
export const mainOuterBox = {
    width: '80%',
    marginX: 'auto',
    maxWidth: '1500px',
    paddingY: '2rem',
}

export const bannerStyles = {
    bannerText: {
        color: 'var(--text-focused)',
        textTransform : 'uppercase',
        textAlign: 'center',
        fontWeight: '900',
        fontSize: {xs: '35px', sm: '50px', md: '40px', lg: '50px', xl: '55px'},
        marginX: 'auto',
        fontFamily: 'Tommy'
    }
};


export const mainStyles = {
    mainOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
    },

    lineDesign: {
        clipPath: {xs:'polygon(100% 45%, 100% 65%, 30% 100%, 0% 100%)', md: 'polygon(100% 0, 100% 15%, 48% 100%, 30% 100%, 90% 0)'},
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: '#001823',
    },

    logoStyles: {
        height: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        width: {xs: '75px', sm: '100px', md: '100px', lg: '125px', xl: '150px'},
        position: 'absolute',
        bottom: {xs: '', md: '5%'},
        top: {xs: '5%', md: ''},
        right: '4%',
    },

    bannerStyle: {
        position: 'relative',
        textAlign: {xs: 'center', md: 'start'},
        fontSize: {xs: '50px', md: '60px', lg: '60px', xl: '60px'},
        fontFamily: 'Tommy',
        color: '#001823',
        fontWeight: '900',
        marginX: {xs: 'auto', md: '0'},
        textTransform: 'uppercase',
        '&::before': {
            content: '""',
            height: {xs: '30px', md: '40px'},
            width: {xs: '10px', md: '12px'},
            backgroundColor: 'var(--accent-col)',
            display: 'block',
            position: 'absolute',
            borderRadius: '10px',
            left: '33.25%',
            top: {xs: '13%', md: '12%'}
        }
    },

    bottomBox: {
        maxWidth: {xs: '100%', sm: '550px', md: '100%'},
        padding: {xs: '2rem 2rem', sm: '2rem 4rem'},
        boxShadow: '0 25px 15px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(255,255,255,0.125)',
        borderRadius: '45px',
        marginX: {xs: 'auto', md: '0'}
    },

    subBannerText: {
        color : '#001823',
        fontSize: {xs: '25px', sm: '45px', md: '25px', lg: '30px', xl: '35px'},
        fontWeight: '900',
        textTransform: 'uppercase',
        marginBottom: '0.5rem',
        fontFamily: 'Tommy'
    },

    text: {
        color: 'var(--text-unFocused)',
        fontSize: {xs: '16px', md: '18px', lg: '19px', xl: '20px'},
        marginTop: '0.5rem',
        fontFamily: 'Treb',
    },

    rightImage: {
        height: '90%',
        width: '100%',
        maxWidth: '575px',
        marginRight: 'auto',
        marginLeft: {xs: 'auto', md: '0'},
    }
}
