import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Slide, Stack, styled, TextField, Typography} from "@mui/material";
import {contactTextLabel, mainBGBOX, mainContainerGrid} from "./stylesheets/cotactStyles";
import {bannerStyles} from "./stylesheets/portfolioStyles";
import {contactBtn} from "./stylesheets/headerStyles";
import {motion} from 'framer-motion';

const Contact = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    const CustomTextField = styled(TextField)({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'var(--text-unFocused)',
            },
            '&:hover fieldset': {
                borderColor: 'var(--text-focused)',
                placeholderColor: 'var(--text-focused)'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--text-focused)',
                placeholderColor: 'var(--text-focused)'
            },
            '& .MuiOutlinedInput-input': {
                color: 'var(--text-focused)',
            },
        },
        '& .MuiOutlinedInput-input': {
            color: 'var(--text-focused)',
        },
        '& .MuiInputLabel-outlined': {
            color: 'var(--text-focused)',
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: 'var(--text-focused)',
        },
    });

    const handleGmailRequest = () => {
        const email = 'support@technotactics.com';
        const subject = 'Get a Quote';

        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}`;

    }

    return(
        <Box id={'contact'}>
            <Box sx={{backgroundColor: 'var(--primary-col)'}}>
                <Stack ref={ref} direction={{xs: 'column', md: 'row'}} sx={{alignItems: 'center', justifyContent: 'center', marginX: 'auto', paddingY: '1.5rem'}}>
                    <Typography sx={bannerStyles.bannerText}>
                        Contact Us
                    </Typography>
                </Stack>
            </Box>
            <Box sx={mainBGBOX}>
                <Grid container sx={mainContainerGrid}>
                    <Slide direction={'right'} in={isVisible} timeout={1500}>
                        <Grid item xs={12} sm={6} sx={{padding: {xs: '0rem', sm: '0.5rem'}}}>
                            <Typography sx={contactTextLabel}>First Name</Typography>
                            <CustomTextField id="f_name" helperText={'Name is required*'} variant="outlined" fullWidth={true}/>
                        </Grid>
                    </Slide>
                    <Slide direction={'left'} in={isVisible} timeout={1500} style={{transitionDelay: '200ms'}}>
                        <Grid item xs={12} sm={6} sx={{padding: {xs: '0rem', sm: '0.5rem'}}}>
                            <Typography sx={contactTextLabel}>Last Name</Typography>
                            <CustomTextField id="l_name" helperText={'Name is required*'} variant="outlined" fullWidth={true}/>
                        </Grid>
                    </Slide>
                    <Slide direction={'right'} in={isVisible} timeout={1500} style={{transitionDelay: '400ms'}}>
                        <Grid item xs={12} sm={6} sx={{padding: {xs: '0rem', sm: '0.5rem'}}}>
                            <Typography sx={contactTextLabel}>Phone Number</Typography>
                            <CustomTextField id="pn" helperText={'Phone Number is required*'} variant="outlined" fullWidth={true}/>
                        </Grid>
                    </Slide>
                    <Slide direction={'left'} in={isVisible} timeout={1500} style={{transitionDelay: '600ms'}}>
                        <Grid item xs={12} sm={6} sx={{padding: {xs: '0rem', sm: '0.5rem'}}}>
                            <Typography sx={contactTextLabel}>Company Name</Typography>
                            <CustomTextField id="cn" variant="outlined" fullWidth={true}/>
                        </Grid>
                    </Slide>
                    <Slide direction={'up'} in={isVisible} timeout={1500} style={{transitionDelay: '800ms'}}>
                        <Grid item xs={12} sx={{padding: {xs: '0rem', sm: '0.5rem'}}}>
                            <Typography sx={contactTextLabel}>Project Details</Typography>
                            <CustomTextField id="pd" variant="outlined" helperText={'Project Details are required*'} multiline rows={4} fullWidth={true}/>
                        </Grid>
                    </Slide>
                    <Grid item xs={12}>
                        <Box sx={{margin: '0 auto 0 auto', width: {xs: '200px', md: '240px'}, height: '50px'}}>
                            <motion.div
                                whileHover={{scale: 1.15}}
                                whileTap={{scale: 0.9}}
                                transition={{type: "spring", stiffness: 400, damping: 17}}
                            >
                                <Typography sx={contactBtn} onClick={()=> handleGmailRequest()}>
                                    Let's get Started
                                </Typography>
                            </motion.div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}

export default Contact;