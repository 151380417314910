import React, {useEffect, useState} from 'react';
import {Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {
    buttonsMainBox,
    navButton,
    buttonsBox,
    drawerStyles,
    borderMainNavBox, iconStyle, navButtonUnSelected, navbarLogo
} from "./stylesheets/navbarStyles";
import {MenuRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import navIcon from './assets/logo.png';
import logo from './assets/tt.svg';

const Navbar = ({props}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [Index, setIndex] = useState(0);
    const [Left, setLeft] = useState(0);

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.only('md'));
    const isLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const isELarge = useMediaQuery(theme.breakpoints.only('xl'));


    const scrollToSection = (id) => {
        if(props === "back"){
            navigate("/");
        }
        const element = document.getElementById(id);
        console.log(id);
        if(props === "back") {
            const interval = setTimeout(()=> {
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                }
                console.log("here")
            }, 2000);
            return ()=> clearTimeout(interval);
        } else {
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY

        if(currentScrollPos > prevScrollPos){
            setVisible(false)
        }else{
            setVisible(true)
        }

        setPrevScrollPos(currentScrollPos)
    }

    useEffect( () => {
        if(props !== "back") {
            window.addEventListener('scroll', handleScroll);

            return () => window.removeEventListener('scroll', handleScroll)
        }
    })

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const navButtons = [
        {
            id: 'home',
            title: 'Home',
        },

        {
            id: 'services',
            title: 'Services',
        },

        {
            id: 'portfolio',
            title: 'Portfolio',
        },

        {
            id: 'about',
            title: 'About'
        },

        {
            id: 'devprocess',
            title: 'Processes',
        },

        {
            id: 'testimonials',
            title: 'Testimonials',
        },


        {
            id: 'contact',
            title: 'Contact Us'
        },
    ]

    const handleButtonClick = (id, index) => {
        setOpen(false);
        setIndex(index);
        console.log(id);
        scrollToSection(id);
        if (index === 0) {
            if(isMedium) {
                setLeft(8 + index * 135);
            } else if (isLarge) {
                setLeft(57 + index * 135);
            } else if (isELarge) {
                setLeft(67 + index * 135);
            }
        } else if (index > 0) {
            if(isMedium) {
                if(index === 1 || index === 2 || index === 3) {
                    setLeft(10 + index * 110 + index);
                } else if (index === 4) {
                    setLeft(10 + index * 110 + index + 2);
                } else if (index === 5) {
                    setLeft(10 + index * 110 + index + 3);
                } else if (index === 6) {
                    setLeft(10 + index * 110 + index + 4);
                }
            } else if (isLarge) {
                if(index === 1) {
                    setLeft(55 + index * 135 + index * 10);
                } else if (index === 2 || index === 3) {
                    setLeft(55 + index * 135 + index * 9);
                } else if (index === 4) {
                    setLeft(55 + index * 135 + index * 8.75);
                } else if (index > 4) {
                    setLeft(55 + index * 135 + index * 8.5);
                }
            } else if (isELarge) {
                setLeft(66 + index * 135 + index * 20);
            }
        }
    }

    useEffect(()=> {
        if(props !== "back") {
            handleButtonClick(navButtons[Index].id, Index);
        }
    }, [isMedium, isLarge, isELarge]);

    return(
        <Box sx={{position: 'fixed', zIndex: 5, width: '100vw', transition: '1s', top: {xs: '0', md: visible ? '0rem' : '-20vh'}, padding: {xs: '0', md: '1rem 0 0 0'}}}>
            {!isSmallScreen ? (
                <Stack direction="row" sx={{alignItems: 'center'}}>
                    <Box component='img' sx={navbarLogo} src={navIcon} alt={'technotactics'}/>
                    <Box sx={borderMainNavBox}>
                        <Box sx={{...buttonsMainBox, '&::before':{
                                left: (Left === 0 ? {md: 8, lg: 56.5, xl: 67} : `${Left}px`),
                                display: 'block',
                                content: '""',
                                height: '6px',
                                width: {md: '112px', lg: '127px', xl: '140px'},
                                backgroundColor: 'white',
                                boxShadow: '0 0 5px 3px rgba(255,255,255,0.5)',
                                borderRadius : '2px',
                                position: 'absolute',
                                bottom: '36px',
                                transition: '0.5s',
                            }}}
                        >
                            <Stack direction="row" sx={buttonsBox}>
                                {navButtons.map((objects, index) => (
                                    <Typography key={index} sx={Index !== index ? {...navButton} : {...navButtonUnSelected}} onClick={()=> handleButtonClick(objects.id, index)}>
                                        {objects.title}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </Stack>) :
                <Stack direction="row" sx={drawerStyles.mainOuterBox}>
                    <Box sx={{...iconStyle, marginX: '0.5rem'}} component="img" src={navIcon} alt={'technotactics'}/>
                    <Box component='img' sx={drawerStyles.navbarLogo} src={logo} alt={'logo'}/>
                    <Box>
                        <Drawer PaperProps={{sx: drawerStyles.drawerSX}} anchor={'top'} open={open} onClose={() => setOpen(false)}>
                            <Box component='img' sx={{height: '100px', width: '75px'}} src={navIcon} alt={'technotactics'}/>
                            <List>
                                {navButtons.map((item, index) =>(
                                    <ListItemButton key={index} sx={{width: '100%'}} onClick={() => handleButtonClick(item.id, index)}>
                                        <ListItemIcon sx={{width: '100%'}}>
                                            <ListItemText sx={drawerStyles.listStyle}>{item.title}</ListItemText>
                                        </ListItemIcon>
                                    </ListItemButton>))}
                            </List>
                        </Drawer>

                        <IconButton sx={{color: 'var(--accent-col)', width: '40px', height: '40px'}} onClick={() => setOpen(!open)}>
                            <MenuRounded />
                        </IconButton>
                    </Box>
                </Stack>
            }
        </Box>
    )
}

export default Navbar;