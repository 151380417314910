import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Badge, Box, Slide, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {bannerStyles, subText} from "./stylesheets/portfolioStyles";
import { register } from 'swiper/element/bundle';
import './stylesheets/swiper.css';
import avatar from './assets/avatart-dummy.png';
import {
    bgBox,
    cardImage,
    cardStyle,
    testimonialBox,
} from "./stylesheets/testimonialsStyles";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import t1 from './assets/testimonials/t1.jpeg';
import t2 from './assets/testimonials/t2.jpeg';
import t3 from './assets/testimonials/t3.jpeg';
import t4 from './assets/testimonials/t4.jpeg';
import t5 from './assets/testimonials/t5.jpeg';
import t6 from './assets/testimonials/t6.jpeg';


register();

const Testimonial = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const theme = useTheme();

    const small = useMediaQuery(theme.breakpoints.down('md'));
    const medium = useMediaQuery(theme.breakpoints.only('md'));
    const large = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
    }, [small, medium, large]);

    const dataSmall = [
        {
            img: t4,
            name: 'Cori Grohman',
            occupation: 'CEO Helika',
            comment: 'Beautiful work! The technotactics team really did there best, literally saving the project. The approach and ' +
                'creativity played a part in our project. We are really pleased with the attention to detail and the ' +
                'approach that was shown to us. A huge thank you for your hard work and we look forward to working again!',
            stars: 4,
        },

        {
            img: t3,
            name: 'Matt Lobel',
            occupation: 'Founder PLAYN',
            comment: 'Technotactics team is a talented, professional and has a lot of potential. There communication was top-notch ' +
                'and there skills were reasonably strong. I enjoyed working with them and will likely have additional jobs for ' +
                'them in the future.They helped me in all aspects of this job and did it really well.',
            stars: 3,

        },

        {
            img: t1,
            name: 'Jackson Roberts',
            occupation: 'CTO Stellar Play Solutions',
            comment: 'Techno Tactics exceeded expectations with their innovative solutions, delivering outstanding ' +
                'results for our gaming project. Looking forward to more success with their creative approach!',
            stars: 4,

        },

        {
            img: t5,
            name: 'Marcus Rodriguez',
            occupation: 'CTO Cyber Pulse Games',
            comment: 'Techno Tactics delivered exceptional results, exceeding our expectations with innovative ' +
                'solutions and meticulous attention to detail. Eagerly anticipating future collaborations!',
            stars: 4,

        },

        {
            img: t6,
            name: 'Ethan Reynolds',
            occupation: 'CEO Nexus Solutions',
            comment: 'Working with them was a pleasure, their professionalism and creativity seamlessly brought our ' +
                'gaming vision to life, surpassing expectations. Excited for more successful endeavors together!',
            stars: 5,

        },

        {
            img: t2,
            name: 'Olivia Xelusa',
            occupation: 'CTO Zenith',
            comment: 'The seamless collaboration and creative approach brought our vision to life. We are genuinely ' +
                'impressed with the results and thankful for the professionalism displayed. Techno Tactics, you have earned a loyal customer here!',
            stars: 5,

        },
    ]

    const swiperElRef = useRef(null);

    useEffect(() => {
        swiperElRef.current.addEventListener('swiperprogress', (e) => {
            const [swiper, progress] = e.detail;
            /*console.log(swiper);*/
        });

        swiperElRef.current.addEventListener('swiperslidechange', (e) => {
            /*console.log(e.detail.activeIndex);*/
        });
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);


    return(
        <Box id="testimonials">
            <Stack ref={ref} sx={{alignItems: 'center', justifyContent: 'center', marginX: 'auto', backgroundColor: 'var(--accent-col)'}}>
                <Typography sx={{...bannerStyles.bannerText, color: '#F1F2F2', marginY: {xs: '1rem', md: '0'}}}>
                    Testimonials
                </Typography>
                <Typography variant="h3" sx={subText}>
                    What our Client Say About TechnoTactics
                </Typography>
            </Stack>
            <Box sx={bgBox}>
                <Box sx={testimonialBox}>
                    <swiper-container className="mySwiper" space-between={20} pagination="true" pagination-clickable="true" slides-per-view = {large ? "3" : medium ? "2" : small ? "1" : "1"} ref={swiperElRef}
                                      style={{
                                          "--swiper-navigation-color": "orange",
                                          "--swiper-pagination-color": "#001823",
                                          "--swiper-pagination-bullet-height": "12px",
                                          "--swiper-pagination-bullet-width": "12px",
                                      }}
                    >
                        {dataSmall.map((item, index) =>(
                            <swiper-slide>
                                <Slide in={isVisible} direction={'down'} timeout={1500} style={{transitionDelay: `${index * 150}ms`}}>
                                    <Stack sx={cardStyle}>
                                        <Badge overlap="circular" anchorOrigin={{vertical: 'top', horizontal: 'right'}} badgeContent={
                                            <Box sx={{borderRadius: '50%', backgroundColor: 'blue', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <FormatQuoteIcon alt="quotes" sx={{padding: '0.1rem', color: 'white'}}/>
                                            </Box>
                                        }>
                                            <Avatar alt="avatar" src={item.img} sx={cardImage}/>
                                        </Badge>
                                        <Typography sx={{marginY: '2rem', fontFamily: 'Treb'}}>{item.comment}</Typography>
                                        <Stack>
                                            <Typography sx={{fontWeight: 'bold', fontFamily: 'Treb'}}>{item.name}</Typography>
                                            <Typography sx={{fontFamily: 'Treb'}}>{item.occupation}</Typography>
                                        </Stack>
                                    </Stack>
                                </Slide>
                            </swiper-slide>))}
                    </swiper-container>
                </Box>
            </Box>
        </Box>
    )
}

export default Testimonial;