
import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Slide, Stack, TextField, Typography} from "@mui/material";
import {bottomStyles, upperStyles} from "./stylesheets/footerStyles";
import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';
import YouTube from '@mui/icons-material/YouTube';
import {contactBtn} from "./stylesheets/headerStyles";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';
import icon from "./assets/service_logo.png";

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const navigate = useNavigate();

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleButtonClick = (id, page) => {
        if(page === "back"){
            navigate("/");
        }
        scrollToSection(id);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    const handleGmailClick = () => {
        const email = 'support@technotactics.com';

        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

        const newWindow = window.open(gmailUrl, '_blank');

        if (newWindow) {
            newWindow.focus();
        } else {
            alert('Please Allow Popups to Open Gmail.');
        }
    }

    const navButtons = [
        {
            id: 'home',
            title: 'Home',
        },

        {
            id: 'services',
            title: 'Services',
        },

        {
            id: 'portfolio',
            title: 'Portfolio',
        },

        {
            id: 'devprocess',
            title: 'Processes'
        },

        {
            id: 'testimonials',
            title: 'Testimonials',
        },

        {
            id: 'contact',
            title: 'Contact Us'
        },

        {
            id: 'careers',
            title: 'Careers'
        }
    ]

    return(<Box>
        <Box sx={upperStyles.upperMainBox}>
            <Stack ref={ref} sx={upperStyles.mainStack}>
                <Slide direction={'right'} timeout={1500} in={isVisible}>
                    <Box component='img' src={icon} alt={'icon'} sx={upperStyles.footerLogo}/>
                </Slide>
                <Slide direction={'left'} timeout={1500} in={isVisible} style={{transitionDelay: '150ms'}}>
                    <Typography sx={upperStyles.infoText}>Want to Start a Project?</Typography>
                </Slide>
                <Slide direction={'right'} timeout={1500} in={isVisible}>
                    <TextField
                        required
                        variant={'outlined'}
                        id="gmail click"
                        placeholder="Describe Your Problem"
                        multiline={true}
                        rows={5}
                        sx={{width: {xs: '95%', sm: '400px'}, marginX: 'auto', transition: '0.5s',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                    borderRadius: '2px',
                                    paddingX: '5rem',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#b2b2b2',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                                '& .MuiOutlinedInput-input': {
                                    fontSize: '17px',
                                    color: 'white',
                                    paddingX: '1.5rem'
                                },
                            },
                            '& .MuiOutlinedInput-input': {
                                color: 'grey',
                            },
                            '& .MuiInputLabel-outlined': {
                                color: 'grey',
                            },
                            '& .MuiInputLabel-outlined.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    />
                </Slide>
                <Box sx={{margin: '0 auto 0 auto', width: {xs: '200px', md: '240px'}, height: '50px'}}>
                    <motion.div
                        whileHover={{scale: 1.15}}
                        whileTap={{scale: 0.9}}
                        transition={{type: "spring", stiffness: 400, damping: 17}}
                    >
                        <Typography sx={contactBtn} onClick={()=> handleGmailClick()}>
                            Proceed
                        </Typography>
                    </motion.div>
                </Box>
            </Stack>
        </Box>
        <Box sx={bottomStyles.bottomMainBox}>
            <Stack sx={bottomStyles.mainStack}>
                <Stack direction="row" gap={2}>
                    <Slide direction={'down'} timeout={1500} in={isVisible} style={{transitionDelay: '100ms'}}>
                        <motion.div whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 5 }}><Box sx={{...bottomStyles.iconOuterBox, backgroundColor: 'blue'}}><Facebook sx={{...upperStyles.iconsStyle}}/></Box></motion.div>
                    </Slide>
                    <Slide direction={'down'} timeout={1500} in={isVisible} style={{transitionDelay: '200ms'}}>
                        <motion.div whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 5 }}><Box
                            sx={{...bottomStyles.iconOuterBox, backgroundColor: 'skyblue'}}><Twitter
                            sx={{...upperStyles.iconsStyle}}/></Box></motion.div>
                    </Slide>
                    <Slide direction={'down'} timeout={1500} in={isVisible} style={{transitionDelay: '400ms'}}>
                        <motion.div whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 5 }}><Box
                            sx={{...bottomStyles.iconOuterBox, backgroundColor: 'darkblue'}}><LinkedIn
                            sx={{...upperStyles.iconsStyle}}/></Box></motion.div></Slide>
                    <Slide direction={'down'} timeout={1500} in={isVisible} style={{transitionDelay: '600ms'}}>
                        <motion.div whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 5 }}><Box
                            sx={{...bottomStyles.iconOuterBox, backgroundColor: 'purple'}}><Instagram
                            sx={{...upperStyles.iconsStyle}}/></Box></motion.div></Slide>
                    <Slide direction={'down'} timeout={1500} in={isVisible} style={{transitionDelay: '800ms'}}>
                        <motion.div whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 5 }}><Box
                            sx={{...bottomStyles.iconOuterBox, backgroundColor: 'red'}}><YouTube
                            sx={{...upperStyles.iconsStyle}}/></Box></motion.div></Slide>
                </Stack>

                <Grid container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {navButtons.map((item, index) => (
                        <Grid key={index} item xs={6} md={3} lg={1.5}>
                            <Typography sx={bottomStyles.linkStyle} onClick={()=> handleButtonClick(item.id, "back")}>{item.title}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Box>
    </Box>)
}

export default Footer;