import React, {useEffect, useRef, useState} from 'react';
import {Box, Collapse, Container, Slide, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MMO from './assets/services-imgs/VR-MMO.jpeg';
import ludo from './assets/services-imgs/Ludo.jpeg';
import color from './assets/services-imgs/Coloring-book-game1.jpeg';
import firefighter from './assets/services-imgs/Fire-Fighter-VR.png';
import RE from "./assets/services-imgs/AR-Realstate.jpeg";
import W from "./assets/services-imgs/Warland.jpeg";
import portfolio from "./assets/portfolio-bg.png";
import {contactBtn, mainStyles} from "./stylesheets/portfolioStyles";
import icon from "./assets/service_logo.png";
import {motion} from "framer-motion";
import portfolio_sm from './assets/portfolio-sm.png';
import {register} from "swiper/element";
import './stylesheets/port-swiper.css';
import 'swiper/swiper-bundle.css';
import Swiper from "swiper";

register();

const Portfolio = () => {
    const [mainIndex, setIndex] = useState(-1);
    const [active, setActive] = useState(2);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const theme = useTheme();

    const small = useMediaQuery(theme.breakpoints.down('md'));
    const medium = useMediaQuery(theme.breakpoints.only('md'));
    const large = useMediaQuery(theme.breakpoints.up('lg'));

    const portfolioData = [
        {
            title: 'MMO',
            image: MMO,
        },

        {
            title: 'Ludo',
            image: ludo,
        },

        {
            title: 'Coloring Book',
            image: color,
        },

        {
            title: 'Fire Fighter',
            image: firefighter,
        },

        {
            title: 'Real Estate',
            image: RE,
        },

        {
            title: 'Warland',
            image: W,
        }
    ]

    const navigate = useNavigate();

    const handleMoreProjects = () => {
        navigate("MoreProjects");
    }

    const swiperElRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: large ? 3 : medium ? 2 : small ? 1 : 1,
            on: {
                slideChange: function () {
                    setActive(this.activeIndex + (small ? 0 : medium ? 1 : 2));
                },
                resize: function () {
                    setActive(this.activeIndex + 2);
                }
            }
        });

        return () => {
            swiper.destroy();
        };
    }, [small, medium, large]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    const handleMouseEnter = (index) => {
        setIndex(index);
    }

    const handleMouseLeft = () => {
        setIndex(-1);
    }

    return(
        <Box id={'portfolio'} sx={{backgroundImage: {xs: `url(${portfolio_sm})` ,md: `url(${portfolio})`},
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', position: 'relative'}}>
            <Container ref={ref} sx={mainStyles.mainOuterContainer} maxWidth='xl'>
                <Stack direction='column' sx={{height: '100%', alignSelf: 'center', width: 'inherit'}}>
                    <Stack direction={{xs: 'column-reverse', md: 'row'}} sx={{width: '100%', alignItems: {xs: 'center', md: 'start'}, justifyContent: {xs: 'center', md: 'start'}, marginY: '3rem', rowGap: {xs: '2rem', md: '0'}, columnGap: {xs: '0', md: '2rem'}}}>
                        <Slide direction={'right'} in={isVisible} timeout={1000} unmountOnExit mountOnEnter>
                            <Box sx={mainStyles.bottomBox}>
                                <Typography sx={mainStyles.subBannerText}><Box component='span' sx={{color: 'var(--accent-col)'}}>Port</Box>folio</Typography>
                                <Typography sx={mainStyles.text}>A Showcase of Our Team Outstanding Featured Games</Typography>
                                <Box component='img' src={icon} alt={'icon'} sx={mainStyles.logoStyles}/>
                                <Box sx={{marginTop: '3rem', marginLeft: 'auto'}}>
                                    <motion.div
                                        whileHover={{scale: 1.15}}
                                        whileTap={{scale: 0.9}}
                                        transition={{type: "spring", stiffness: 400, damping: 17}}
                                    >
                                        <Typography sx={contactBtn} onClick={() => handleMoreProjects()}>
                                            More Projects
                                        </Typography>
                                    </motion.div>
                                </Box>
                            </Box>
                        </Slide>
                        <Slide direction={'left'} in={isVisible} timeout={1000} unmountOnExit mountOnEnter>
                            <Box component='img' src={icon} alt={'icon'} sx={mainStyles.logoRight}/>
                        </Slide>
                    </Stack>
                    <Stack sx={{
                        width: {xs: '260px', sm: '550px', md: '850px', lg: '1150px', xl: '1450px'},
                        height: {xs: '275px', sm: '500px', md: '400px', lg: '400px', xl: '500px'},
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: {xs: '2rem'},
                        marginX: 'auto',
                        overflow: 'hidden'
                    }}>
                        <div className="swiper-container" ref={swiperElRef}>
                            <div className="swiper-wrapper">
                                {portfolioData.map((item, index) => (
                                    <div key={index} className="swiper-slide">
                                        <Slide direction={'down'} in={isVisible} timeout={1500} style={{ transitionDelay: `${index * 150}ms` }}>
                                            <Box sx={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <Box sx={{height: (active === index ? '85%': '70%'), width: (active === index ? '85%': '70%'), transition: '0.5s', borderRadius: '35px', overflow: 'hidden', boxShadow: {xs: '-10px 10px 7px rgba(0,0,0,0.25)', md: '-20px 20px 15px rgba(0,0,0,0.25)'}, position: 'relative'}} onMouseEnter={()=> handleMouseEnter(index)} onMouseLeave={()=> handleMouseLeft()}>
                                                    <Box component="img" src={item.image} alt={item.title} sx={{height: '100%', width: '100%'}}/>
                                                    <Box sx={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.25)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'absolute', top: 0, left: 0}}>
                                                        <Typography sx={{fontSize: '30px', fontWeight: 'bold', color: 'var(--text-focused)', fontFamily: 'Treb', transition: 'transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55)', transform: mainIndex === index ? 'translateY(-20px)' : 'none'
                                                        }}>{item.title}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Slide>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default Portfolio;